import Loadable from "@loadable/component";
const CardCategories = Loadable(() => import("./CardCategories"));

function CardCategoriesAsyncLoading() {
  return null;
}

const CardCategoriesContainer = (props) => {
  return (
    <CardCategories {...props} fallback={<CardCategoriesAsyncLoading />} />
  );
};

export default CardCategoriesContainer;
