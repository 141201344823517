import { Fragment, useMemo } from "react";

import useReduxState from "hooks/useReduxState";

const useEntityCard = (config, name, props) => {
  const {
    selector,
    loadingSelector,
    desktop: desktopCard,
    mobile: mobileCard,
    overview: overviewCard,
    compact: compactCard,
    follow: followCard,
    entity_type,
  } = config;

  const {
    entity_id: passedEntityId,
    layout,
    overview,
    compact,
    mobile,
  } = props;
  const passedEntity = props[name] || props.entity;
  const entity_id = passedEntity ? passedEntity.get("id") : passedEntityId;

  const loadedEntity = useReduxState(
    (state) => selector && selector(state, entity_id),
    [entity_id]
  );
  const entity =
    passedEntity || props[name] || props[entity_type] || loadedEntity;

  const loading = useReduxState(
    (state) => loadingSelector && loadingSelector(state, entity_id),
    [entity_id]
  );

  const CardType = useMemo(() => {
    if (!entity) {
      return Fragment;
    } else if (overviewCard && (layout === "overview" || overview)) {
      return overviewCard;
    } else if (compactCard && (layout === "compact" || compact)) {
      return compactCard;
    } else if (followCard && layout === "follow") {
      return followCard;
    } else if (mobileCard && (layout === "mobile" || mobile)) {
      return mobileCard;
    } else if (desktopCard) {
      return desktopCard;
    }

    return Fragment;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, overview, compact, mobile, entity]);

  const cardProps = { [name]: entity, loading, entity_type };

  return { ...cardProps, CardType, cardProps };
};

export default useEntityCard;
