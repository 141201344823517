import useEntityCard from "../useEntityCard";
import EpisodeCompactCard from "./EpisodeCompactCardAsync";
import EpisodeDesktopCard from "./EpisodeDesktopCardAsync";
import EpisodeMobileCard from "./EpisodeMobileCard";
import EpisodeOverview from "./EpisodeOverviewAsync";

import { selectSpecificEpisode, selectEpisodeLoading } from "selectors/podcast";

const CARD_CONFIG = {
  selector: selectSpecificEpisode,
  loadingSelector: selectEpisodeLoading,
  desktop: EpisodeDesktopCard,
  mobile: EpisodeMobileCard,
  overview: EpisodeOverview,
  compact: EpisodeCompactCard,
};

const EpisodeCardContainer = (props) => {
  const { CardType, cardProps } = useEntityCard(CARD_CONFIG, "episode", props);

  return <CardType {...props} {...cardProps} />;
};

export default EpisodeCardContainer;
