import PropTypes from "prop-types";
import { Component } from "react";

import { selectUserHasRole } from "selectors/auth";

import useReduxState from "hooks/useReduxState";

class IgnoreErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if (!this.props.isAdmin) {
        return null;
      }
      return <div>{this.state.error.message}</div>;
    }

    return this.props.children;
  }
}

export const withIgnoreErrorBoundary =
  (WrappedComponent) =>
  ({ children, ...rest }) =>
    (
      <IgnoreErrorBoundary>
        <WrappedComponent {...rest}>{children}</WrappedComponent>
      </IgnoreErrorBoundary>
    );

IgnoreErrorBoundary.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
};

IgnoreErrorBoundary.defaultProps = {
  children: null,
  isAdmin: false,
};

const IgnoreErrorBoundaryRedux = (props) => {
  const isAdmin = useReduxState(
    (state) => selectUserHasRole(state, "admin"),
    []
  );

  return <IgnoreErrorBoundary {...props} isAdmin={isAdmin} />;
};

export default IgnoreErrorBoundaryRedux;
